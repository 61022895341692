import React from "react";
import Footer from "../../Components/Footer/Footer";
import PublicationsArticle from "./PublicationsArticle";
import PublicationsBanner from "./PublicationsBanner";
import PublicationsEvents from "./PublicationsEvents";
import PublicationsNewsletter from "./PublicationsNewsletter";

const Publications = () => {
  return (
    <div>
      <PublicationsBanner />
      <PublicationsArticle />
      <PublicationsEvents />
      <PublicationsNewsletter />
      <Footer />
    </div>
  );
};

export default Publications;
