import React from "react";
import "./css/Governance.css";
import { governance_data } from "../../Data";

const Governance = () => {
  return (
    <div className="governance" id={"governance"}>
      <div className="governance__header">
        <h1>The Governance</h1>
      </div>
      <div className="governance__cards">
        {governance_data.map(({ id, header, desc, icon, divider }) => (
          <div className={`governance__${id}`}>
            <div className="governace__icon__container">
              <img src={icon} alt={header} />
            </div>
            <div className="governance__card__header">
              <h3>{header}</h3>
            </div>
            <div className="governance__card__divider">
              <img src={divider} alt="" />
            </div>
            <div className="governance__card__text">
              <p>{desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Governance;
