import React from "react";
import { community } from "../../Data";
import "./css/Community.css";

const Community = () => {
  return (
    <div className="community" id="community">
      <div className="community__Img__desc__container">
        <div className="community__Img__container"></div>
        <div className="community__desc__container">
          <div className="community__header__container">
            <h1>{community.header}</h1>
          </div>
          <div className="community__desc">
            <p>{community.desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
