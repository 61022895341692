import React from "react";
import "./Button.css";

const Button = (props) => {
  return (
    <div className="button">
      <div className="button__container">
        <button onClick={props.func}>{props.title}</button>
      </div>
    </div>
  );
};

export default Button;
