import React, { useRef, useState } from 'react'
import './css/MobileNavigation.css'
import { NavLink } from 'react-router-dom'
import logo from '../../Assets/shadao__logo.png'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

const MobileNavigation = () => {
  const [clicked, setClicked] = useState(true)
  const mobileRef = useRef()
  const showNavBar = () => {
    mobileRef.current.classList.toggle('active__mobile')
    setClicked(!clicked)
  }
  let activeClassName = 'active'

  return (
    <div className="mobile__navigation">
      <div className="mobile__navigation__top">
        <div className="mobile__navigation__logo">
          <img src={logo} alt="" />
        </div>
        <div className="mobile__navigation__ic">
          {clicked ? (
            <AiOutlineMenu
              className="mobile__navigation__menu__ic"
              onClick={showNavBar}
            />
          ) : (
            <AiOutlineClose
              className="mobile__navigation__menu__ic"
              onClick={showNavBar}
            />
          )}
        </div>
      </div>
      <div className="mobile__navigation__links__container" ref={mobileRef}>
        <div className="mobile__navigation__links">
          <nav>
            <ul>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? activeClassName : 'link'
                  }
                  end
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive ? activeClassName : 'link'
                  }
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/projects"
                  className={({ isActive }) =>
                    isActive ? activeClassName : 'link'
                  }
                >
                  Projects
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/publications"
                  className={({ isActive }) =>
                    isActive ? activeClassName : 'link'
                  }
                >
                  Publications
                </NavLink>
              </li>
              <li>
                <NavLink to="/get-involved" className="link">
                  Get Involved
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery" className="link">
                  Gallery
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default MobileNavigation
