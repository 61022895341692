import React, { useState, useRef } from 'react'
import Button from '../../Components/Button/Button'
import './css/PublicationsNewsletter.css'
import emailjs from '@emailjs/browser'
import Alert from '../../Components/Snackbar/Alert'

const PublicationsNewsletter = () => {
  //
  const [open, setOpen] = React.useState(false)
  const handleClick = () => {
    setOpen(true)
    setValid(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  //
  const mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
  const [isValid, setValid] = useState(false)
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault()
    const emailAddress = document.querySelector('.user__email').value
    if (emailAddress.match(mailformat)) {
      setValid(true)
    }
    if (isValid) {
      emailjs
        .sendForm(
          'service_p09m0vh',
          'template_9woj4df',
          form.current,
          'user_3B2e2ZcqLpkP842VpH8Bt',
        )
        .then(
          (result) => {},
          (err) => {},
        )
    }
    document.querySelector('.user__email').value = ''
  }
  return (
    <div className="publications__newsletter" id="publications__newsletter">
      <div className="publications__newsletter__container">
        <div className="publications__newsletter__header">
          <h1>Join our newsletter</h1>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <div className="publications__newsletter__register">
            <input
              type="email"
              placeholder="Enter your email"
              className="user__email"
            />
            <textarea name="message" hidden>
              This is a subscription email
            </textarea>
            <Button title="Subscribe" func={handleClick} />
            <Alert
              open={open}
              handleClose={handleClose}
              msg={
                isValid
                  ? 'Thank you for subscribing'
                  : 'Please enter valid email address'
              }
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default PublicationsNewsletter
