import React from "react";
import "./css/GetInvolvedCommunity.css";
import { get_involved_data } from "../../Data";

const GetInvolvedCommunity = () => {
  return (
    <div className="get__involved__community" id="get__involved__community">
      <div className="get__involved__community__container">
        <div className="get__involved__community__Img"></div>
        <div className="get__involved__community__desc">
          <div className="get__involved__community__header">
            <h1>{get_involved_data.header}</h1>
          </div>
          <div className="get__involved__community__para">
            <p>{get_involved_data.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInvolvedCommunity;
