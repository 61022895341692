import React from "react";
import Button from "../../Components/Button/Button";
import Logo from "../../Components/Logo/Logo";
import Navbar from "../../Components/Navbar/Navbar";
import { TbBrandWhatsapp } from "react-icons/tb";
import { TiSocialGooglePlusCircular } from "react-icons/ti";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { sendWhatsAppMSG } from "../../SocialMedia";
import { faceBookLink } from "../../SocialMedia";
import "./css/GetInvolvedBanner.css";
import { HashLink } from "react-router-hash-link";
import MobileNavigation from "../../Components/MobileNavigation/MobileNavigation";
import MobileHook from "../../Hooks/MobileHook";

const GetInvolvedBanner = ({ width, isMobile }) => {
  const devWidth = MobileHook(width);
  return (
    <div className="get__involved__banner" id="get__involved__banner">
      {devWidth.width <= 450 ? (
        <div className="get__involved__navigation__mobile">
          <MobileNavigation />
        </div>
      ) : (
        <div className="get__involved__navigation">
          <Logo />
          <Navbar />
          {/* <WeatherWidget /> */}
        </div>
      )}

      <div className="get__involved__container">
        <div className="get__involved__theme">
          <h1>Get Involved</h1>
          <p className="get__involved__banner__para">
            Do you feel blessed and honored to share your skills and time for
            greater cause?
          </p>
          <div className="get__involved__btn">
            <HashLink to="/get-involved/#get__involved__community">
              <Button title="Learn how" />
            </HashLink>
          </div>
        </div>
        <div className="get__ivolved__social__icons">
          <div>
            <TbBrandWhatsapp
              className="get__involved__whats__app__ic"
              onClick={sendWhatsAppMSG}
            />
          </div>
          <div>
            <a href="mailto: shadaotanzania@gmail.com">
              <TiSocialGooglePlusCircular className="get__involved__gmail__ic" />
            </a>
          </div>
          <div>
            <TiSocialFacebookCircular
              className="get__ivolved__facebook__ic"
              onClick={faceBookLink}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInvolvedBanner;
