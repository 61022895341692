import React from "react";
import { HashLink } from "react-router-hash-link";
import Button from "../../Components/Button/Button";
import Logo from "../../Components/Logo/Logo";
import MobileNavigation from "../../Components/MobileNavigation/MobileNavigation";
import Navbar from "../../Components/Navbar/Navbar";
import MobileHook from "../../Hooks/MobileHook";
import "./css/PublicationsBanner.css";

const PublicationsBanner = ({ width, isMobile }) => {
  const devWidth = MobileHook(width);
  return (
    <div className="publications__banner" id="publications__banner">
      {devWidth.width <= 450 ? (
        <div className="publications__nav__container__mobile">
          <MobileNavigation />
        </div>
      ) : (
        <div className="publications__nav__container">
          <Logo />
          <Navbar />
          {/* <WeatherWidget /> */}
        </div>
      )}

      <div className="publications__theme">
        <div className="publications__theme__header">
          <p className="publications__theme__para1">Sustainable livelihood</p>
          <p className="publications__theme__para2">
            The study applied Sustainable Livelihood Approach (SLA) theoretical
            framework to explain factor hindering livestock production for
            livelihood development in selected villages.
          </p>
        </div>
        <div className="publications__theme__btn">
          <HashLink to="/publications/#publications__article">
            <Button title="Learn More" />
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default PublicationsBanner;
