import React from "react";
import "./css/GalleryMid.css";
import { upperImages } from "../../Data";
import { motion } from "framer-motion";

const GalleryMid = ({ setSelectedImg, setClassName }) => {
  return (
    <div className="gallery__mid">
      <div className="gallery__mid__header">
        <p>
          <span className="gallery__first__blk">Cultural</span> Heritage
        </p>
      </div>
      <div className="gallery__mid__Img__container">
        <div className="gallery__mid__Img__grid__container">
          <div className="gallery__mid__Img__">
            {upperImages.map(({ id, path }) => (
              <motion.div
                className={id}
                onClick={() => {
                  setSelectedImg(path);
                  setClassName(`${id}__img`);
                }}
              >
                <motion.img
                  src={path}
                  alt=""
                  layout
                  whileHover={{ opacity: 1 }}
                />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryMid;
