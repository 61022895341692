import React from "react";
import "./css/Objectives.css";
import { activities } from "../../Data";

const Objectives = () => {
  return (
    <div className="objectives">
      <div className="community__cards__container">
        {activities.map(({ title, text, img_front, img_back }) => (
          <div className="flip__card">
            <div className="flip__card__inner">
              <div className="community__card__container">
                <div className="community__card__Img__front">
                  <img src={img_front} alt={title} />
                </div>
                <div className="community__card__title">
                  <p>{title}</p>
                </div>
              </div>
              <div className="community__card__back">
                <div className="community__card__Img__back">
                  <img src={img_back} alt={title} />
                </div>
                <div className="community__card__text">
                  <p>{text}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Objectives;
