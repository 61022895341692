import { Route, Routes } from 'react-router-dom'
import PageNotFound from './Components/Error/PageNotFound'
// import MobileNavigation from "./Components/MobileNavigation/MobileNavigation";
import ScrollButton from './Components/ScrollButton/ScrollButton'
import About from './Views/About/About'
import Gallery from './Views/Gallery/Gallery'
import GetInvolved from './Views/GetInvolved/GetInvolved'
import Home from './Views/Home/Home'
import Project from './Views/Projects/Project'
import Publications from './Views/Publications/Publications'

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/get-involved" element={<GetInvolved />} />
        <Route path="/publications" element={<Publications />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route element={<PageNotFound />} />
      </Routes>
      <ScrollButton />
    </div>
  )
}

export default App
