import React from 'react'
import './PageNotFound.css'

const PageNotFound = () => {
  return (
    <div className="page__not__found">
      <div className="page__not__found__container"></div>
    </div>
  )
}

export default PageNotFound
