import { useState, useEffect } from "react";

const MobileHook = () => {
  const [width, setWidth] = useState(window.innerWidth - 60);
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth - 60);
      if (width <= 450) {
        setMobile(true);
      }
    });
  }, [width, isMobile]);
  return { width, isMobile };
};

export default MobileHook;
