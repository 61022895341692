import React from "react";
import { animalProjectGallery } from "../../Data";
import { motion } from "framer-motion";
import "./css/GalleryProject.css";

const GalleryProject = ({ setSelectedImg, setClassName }) => {
  return (
    <div className="gallery__project">
      <div className="gallery__project__header">
        <p>
          {" "}
          <span className="gallery__project__blk">Animal</span> Health Project
        </p>
      </div>
      <div className="galley__project__container">
        <div className="gallery__project__grid">
          {animalProjectGallery.map(({ id, path }) => (
            <motion.div
              className={id}
              onClick={() => {
                setSelectedImg(path);
                setClassName(`${id}__img`);
              }}
            >
              <motion.img
                src={path}
                alt={id}
                layout
                whileHover={{ opacity: 1 }}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryProject;
