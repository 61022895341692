import React from "react";
import AboutBanner from "./AboutBanner";
import "./css/AboutBanner.css";
import Governance from "./Governance";
import Mission from "./Mission";
import Profile from "./Profile";
import Footer from "../../Components/Footer/Footer";

const About = () => {
  return (
    <div>
      <AboutBanner />
      <Profile />
      <Mission />
      <Governance />
      <Footer />
    </div>
  );
};

export default About;
