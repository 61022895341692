import React from 'react'
import { project_data } from '../../Data'
import './css/ProjectDisability.css'

const ProjectDisability = () => {
  return (
    <div className="project__disability" id="project__disability">
      <div className="project__disability__container">
        <div className="project__disability__Img"></div>
        <div className="project__disablity__desc">
          <div className="project__disability__header">
            <p>{project_data[4].header}</p>
          </div>
          <div className="project__disability__para">
            <p>{project_data[4].desc}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDisability
