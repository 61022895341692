import React from "react";
import Logo from "../../Components/Logo/Logo";
import MobileNavigation from "../../Components/MobileNavigation/MobileNavigation";
import Navbar from "../../Components/Navbar/Navbar";
import MobileHook from "../../Hooks/MobileHook";

const AboutBanner = ({ width, isMobile }) => {
  const devWidth = MobileHook(width);
  return (
    <div className="about__banner" id="about__banner">
      {devWidth.width <= 450 ? (
        <div className="about__banner__container__mobile">
          <MobileNavigation />
        </div>
      ) : (
        <div className="about__banner__container">
          <Logo />
          <Navbar />
        </div>
      )}

      <div className="about__org__theme">
        <div className="theme__container">
          <p className="major">Empowering</p>
          <p className="minor">the community at all levels</p>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;
