import React from "react";
import "./css/PublicationsArticle.css";
import pdf from "../../Doc/survey_report.pdf";

const PublicationsArticle = () => {
  const openPDF = (e) => {
    window.open(pdf);
  };
  return (
    <div className="publications__article" id="publications__article">
      <div className="publications__article__container">
        <div className="publications__article__header">
          <h1>Article</h1>
        </div>
        <div className="publications__article__doc__link">
          <div className="publications__article__doc">
            <div className="publication__article__title">
              <h3>Baseline survey</h3>
              <p>The survey was conducted in 2020</p>
            </div>
          </div>
          <div className="publication__article__link">
            <div className="publications__article__link__body">
              <div className="publications__article__link__title">
                <h6>
                  FACTORS HINDERING LIVESTOCK PRODUCTION IN LUSHOTO DISTRICT
                  TANZANIA
                </h6>
              </div>
              <div className="publications__article__link__liner"></div>
              <div
                className="publications__article__link__btn"
                onClick={openPDF}
              >
                Read an article
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicationsArticle;
