import React, { useRef, useEffect, useState } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import shadaoLocation from '../../shadaoLocation.json'
import './css/GetInvolvedContact.css'
import Button from './../../Components/Button/Button'
import emailjs from '@emailjs/browser'
import Alert from '../../Components/Snackbar/Alert'

//
mapboxgl.accessToken =
  'pk.eyJ1IjoiY2hyaXNhZGJyIiwiYSI6ImNsMWllNzFteDBzbm0zZHF1dzMwb3p1aXYifQ.O5MZV7XZSA-DodMVAZJjxQ'
//
const GetInvolvedContact = () => {
  const mapContainerRef = useRef(null)

  const [lng, setLng] = useState(38.2902)
  const [lat, setLat] = useState(-4.7987)
  const [zoom, setZoom] = useState(15)
  //
  const [isValid, setValid] = useState(false)
  const form = useRef()
  //
  const [open, setOpen] = React.useState(false)
  const handleClick = () => {
    setOpen(true)
    setValid(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  //
  const mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g

  //
  const sendEmail = (e) => {
    e.preventDefault()
    const emailAddress = document.querySelector('.contact__user__email').value
    const userName = document.querySelector('.user__name').value
    const message = document.querySelector('.get__involved__contact__textarea')
      .value
    if (
      emailAddress.match(mailformat) &&
      message.length > 10 &&
      userName.length > 0
    ) {
      setValid(true)
    }
    if (isValid) {
      emailjs
        .sendForm(
          'service_p09m0vh',
          'template_9woj4df',
          form.current,
          'user_3B2e2ZcqLpkP842VpH8Bt',
        )
        .then(
          (result) => {
            console.log(result.text)
          },
          (err) => {
            console.log(err.text)
          },
        )
    }
    document.querySelector('.contact__user__email').value = ''
    document.querySelector('.user__name').value = ''
    document.querySelector('.get__involved__contact__textarea').value = ''
  }
  //
  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom,
    })

    // Create default markers
    shadaoLocation.features.map((feature) =>
      new mapboxgl.Marker().setLngLat(feature.geometry.coordinates).addTo(map),
    )

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'top-right')

    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4))
      setLat(map.getCenter().lat.toFixed(4))
      setZoom(map.getZoom().toFixed(2))
    })

    // Clean up on unmount
    return () => map.remove()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="get__involved__contact">
      <div className="get__involved__contact__map__container">
        <div className="get__involved__contact__form">
          <div className="get__involved__contact__form__header">
            <h1>Get in touch</h1>
          </div>
          <div className="get__involved__contact__form__items">
            <form ref={form} onSubmit={sendEmail}>
              <div>
                <input
                  type="text"
                  placeholder="Enter your name"
                  name="user_name"
                  autoComplete="off"
                  className="user__name"
                />
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Enter your email"
                  name="user_email"
                  autoComplete="off"
                  className="contact__user__email"
                />
              </div>
              <div>
                <textarea
                  placeholder="Message"
                  className="get__involved__contact__textarea"
                  name="message"
                ></textarea>
              </div>
              <div>
                <Button title="Send Message" func={handleClick} />
                <Alert
                  open={open}
                  handleClose={handleClose}
                  msg={
                    isValid
                      ? 'Thank you for contacting us'
                      : 'Please fill all the fields'
                  }
                />
              </div>
            </form>
          </div>
        </div>
        <div className="get__involved__contact__map">
          <div className="map-container" ref={mapContainerRef} />
        </div>
      </div>
    </div>
  )
}

export default GetInvolvedContact
