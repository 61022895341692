export const date = () => {
  const months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "Sep",
    "October",
    "Nov",
    "Dec",
  ];
  const d = new Date();
  return `${months[d.getMonth()]} ${d.getFullYear()}`;
};
