import React from "react";
import "./css/ProjectHeritage.css";
import { project_data } from "../../Data";

const ProjectHeritage = () => {
  return (
    <div className="project__heritage" id="project__heritage">
      <div className="project__heritage__container">
        <div className="project__heritage__Img"></div>
        <div className="project__heritage__desc">
          <div className="project__desc__header">
            <h1>{project_data[0].header}</h1>
          </div>
          <div className="project__heritage__para">
            <p>{project_data[0].desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectHeritage;
