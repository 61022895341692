import React from 'react'
import ProjectAnimal from './ProjectAnimal'
import ProjectBanner from './ProjectBanner'
import ProjectChildren from './ProjectChildren'
import ProjectHeritage from './ProjectHeritage'
import ProjectWasteManagement from './ProjectWasteManagement'
import Footer from '../../Components/Footer/Footer'
import ProjectDisability from './ProjectDisability'

const Project = () => {
  return (
    <div>
      <ProjectBanner />
      <ProjectHeritage />
      <ProjectDisability />
      <ProjectChildren />
      <ProjectWasteManagement />
      <ProjectAnimal />
      <Footer />
    </div>
  )
}

export default Project
