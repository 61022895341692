import React, { useRef, useState } from 'react'
import './css/Footer.css'
import { TbBrandWhatsapp } from 'react-icons/tb'
import {
  TiSocialGooglePlusCircular,
  TiSocialFacebookCircular,
} from 'react-icons/ti'
import { sendWhatsAppMSG } from '../../SocialMedia'
import { faceBookLink } from '../../SocialMedia'
import Button from '../Button/Button'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'
import emailjs from '@emailjs/browser'
import Alert from '../Snackbar/Alert'

const Footer = () => {
  const [open, setOpen] = React.useState(false)
  const handleClick = () => {
    setOpen(true)
    setValid(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  //
  const mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
  const [isValid, setValid] = useState(false)
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault()
    const emailAddress = document.querySelector('.donate__email').value
    if (emailAddress.match(mailformat)) {
      setValid(true)
      console.log(emailAddress)
    }
    if (isValid) {
      emailjs
        .sendForm(
          'service_p09m0vh',
          'template_9woj4df',
          form.current,
          'user_3B2e2ZcqLpkP842VpH8Bt',
        )
        .then(
          (result) => {},
          (err) => {},
        )
    }
    document.querySelector('.donate__email').value = ''
  }
  return (
    <div className="footer">
      <div className="footer__container">
        <div className="about__footer__links">
          <ul className="about">
            <li>
              <HashLink to="/about/#about__banner" className="about__header">
                About
              </HashLink>
            </li>
            <li>
              <HashLink to="/about/#governance" className="about__link">
                The Governance
              </HashLink>
            </li>
            <li>
              <HashLink to="/about/#governance" className="about__link">
                The Members
              </HashLink>
            </li>
            <li>
              <HashLink to="/about/#governance" className="about__link">
                Board of Directors
              </HashLink>
            </li>
            <li>
              <HashLink to="/about/#governance" className="about__link">
                The Management Team
              </HashLink>
            </li>
          </ul>
        </div>
        <div className="projects__footer__links">
          <ul className="projects">
            <li>
              <HashLink
                to="/projects/#project__banner"
                className="project__header"
              >
                Projects
              </HashLink>
            </li>

            <li>
              <HashLink
                to="/projects/#project__heritage"
                className="project__link"
              >
                Community Heritage Program
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/projects/#project__disability"
                className="project__link"
              >
                Children Disabilities Rehabilitation{' '}
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/projects/#project__children"
                className="project__link"
              >
                Children Support Program
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/projects/#waste__material__project"
                className="project__link"
              >
                Waste Material Management and Reuse Program
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/projects/#project__animal"
                className="project__link"
              >
                Community Animal Project
              </HashLink>
            </li>
          </ul>
        </div>
        <div className="publications__footer__links">
          <ul className="publications__footer">
            <li>
              <HashLink
                to="/publications/#publications__banner"
                className="publications__footer__header"
              >
                Publications
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/publications/#publications__article"
                className="publication__link"
              >
                Reports
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/publications/#publications__events"
                className="publication__link"
              >
                Events
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/publications/#publications__newsletter"
                className="publication__link"
              >
                News Letter
              </HashLink>
            </li>
          </ul>
        </div>
        <div className="getInvolved__footer__links">
          <ul className="getInvolved__footer">
            <li className="get__Involved__link">
              <HashLink
                to="/get-involved/#get__involved__banner"
                className="get__Involved__header"
              >
                Get Involved
              </HashLink>
            </li>
          </ul>
          <div className="get__involved__form__container">
            <form ref={form} onSubmit={sendEmail}>
              <input
                type="email"
                placeholder="Enter your email"
                // name="user_email"
                className="donate__email"
              />
              <textarea hidden name="message">
                Please reply this is a donation email
              </textarea>
              <Button title="Donate" func={handleClick} />
              <Alert
                open={open}
                handleClose={handleClose}
                msg={
                  isValid
                    ? 'Thank you, we will contact you soon'
                    : 'Please enter valid email address'
                }
              />
            </form>
          </div>
          <div className="footer__social__media">
            <div>
              <Link to="#" onClick={sendWhatsAppMSG}>
                <TbBrandWhatsapp className="footer__whats__app__ic" />
              </Link>
            </div>
            <div>
              <Link to="#" onClick={faceBookLink}>
                {' '}
                <TiSocialFacebookCircular className="footer__facebook__ic" />
              </Link>
            </div>
            <div>
              <Link to="mailto: shadaotanzania@gmail.com">
                <TiSocialGooglePlusCircular className="footer__gmail__ic" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__divider__container">
        <span className="liner"></span>
      </div>
      <div className="footer__copyright__container">
        <div className="footer__copyright__contents">
          <p>SHADAO &copy; {new Date().getFullYear()} All Rights Reserved</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
