import React from "react";
import "./css/Banner.css";
import Navbar from "../../Components/Navbar/Navbar";
import Button from "../../Components/Button/Button";
import Logo from "../../Components/Logo/Logo";
import { HashLink } from "react-router-hash-link";
import MobileNavigation from "../../Components/MobileNavigation/MobileNavigation";
import MobileHook from "../../Hooks/MobileHook";

const Banner = ({ width, isMobile }) => {
  const devWidth = MobileHook(width);
  return (
    <div className="home__banner">
      {devWidth.width <= 450 ? (
        <div className="home__banner__top__mobile">
          <MobileNavigation />
        </div>
      ) : (
        <div className="home__banner__top">
          <Logo />
          <Navbar />
          {/* <WeatherWidget /> */}
        </div>
      )}
      <div className="vision_statement__container">
        <p className="vision__statement__top">Community</p>
        <p className="vision__statement__bottom">with better livelihood</p>
        <HashLink to="/#community">
          <Button title="Learn More" />
        </HashLink>
      </div>
    </div>
  );
};
//
export default Banner;
