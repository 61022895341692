import React from "react";
import "./css/Mission.css";

const Mission = () => {
  return (
    <div className="mission">
      <div className="mission__container">
        <div className="mission__statemement">
          <div className="mission__header">
            <h1>Mission Statement</h1>
          </div>
          <p className="mission__text">
            "To promote sustainable utilization of available resources through
            trainings, seminars, research and collaborating with community,
            Government and other development stakeholders"
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mission;
