import React from "react";
import Footer from "../../Components/Footer/Footer";
import Banner from "./Banner";
import CarouselHome from "./CarouselHome";
import Community from "./Community";
import Objectives from "./Objectives";

const Home = () => {
  return (
    <div>
      <Banner />
      <Objectives />
      <CarouselHome />
      <Community />
      <Footer />
    </div>
  );
};

export default Home;
