import React from "react";
import "./css/ProjectAnimal.css";
import { project_data } from "../../Data";

const ProjectAnimal = () => {
  return (
    <div className="project__animal" id="project__animal">
      <div className="project__animal__container">
        <div className="project__animal__Img"></div>
        <div className="project__animal__desc">
          <div className="project__animal__header">
            <p>{project_data[3].header}</p>
          </div>
          <div className="project__animal__para">
            <p>{project_data[3].desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAnimal;
