import React from "react";
import "./css/PublicationsEvents.css";
import { date } from "../../Components/Date/Date";
import { event_gallery } from "../../Data";

const PublicationsEvents = () => {
  return (
    <div className="publications__events" id="publications__events">
      <div className="publications__events__container">
        <div className="publications__events__gallery">
          {event_gallery.map(({ id, path }) => (
            <div className="publications__event__Img">
              <img src={path} alt={id} />
            </div>
          ))}
        </div>
        <div className="publications__events__calendar">
          <div className="publications__events__cal__header">
            <h1>Calendar of events</h1>
          </div>
          <div className="publications__events__cal__date">
            <p className="publications__events__month">{date()}</p>
          </div>
          <div className="publications__events__list">
            <p>No events scheduled</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicationsEvents;
