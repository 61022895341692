import React from 'react'
import Logo from '../../Components/Logo/Logo'
import Navbar from '../../Components/Navbar/Navbar'
import Button from '../../Components/Button/Button'
// import WeatherWidget from "../../Components/WeatherWidget/WeatherWidget";
import './css/ProjectBanner.css'
import { HashLink } from 'react-router-hash-link'
import MobileNavigation from '../../Components/MobileNavigation/MobileNavigation'
import MobileHook from '../../Hooks/MobileHook'

const ProjectBanner = ({ width, isMobile }) => {
  const devWidth = MobileHook(width)
  return (
    <div className="project__banner" id="project__banner">
      <div className="project__banner__container">
        {devWidth.width <= 450 ? (
          <div className="project__banner__nav__mobile">
            <MobileNavigation />
          </div>
        ) : (
          <div className="project__banner__nav">
            <Logo />
            <Navbar />
            {/* <WeatherWidget /> */}
          </div>
        )}
        <div className="project__banner__theme">
          <p className="project__banner__p1">Animal</p>
          <p className="project__banner__p1__minor__">Health Project</p>
          <p className="project__banner__p2">
            Livestock subsector is facing number of challenges including low
            productivity, animal diseases, feeds and access to market for
            livestock and livestock products
          </p>
          <div className="project__banner__btn">
            <HashLink to="/projects/#project__animal">
              <Button title="Learn More" />
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectBanner
