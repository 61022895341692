import React, { useState } from "react";
import GalleryBanner from "./GalleryBanner";
import GalleryMid from "./GalleryMid";
import Footer from "../../Components/Footer/Footer";
import GalleryTheme from "./GalleryTheme";
import GalleryModal from "./GalleryModal";
import GalleryProject from "./GalleryProject";

const Gallery = () => {
  const [selectedImg, setSelectedImg] = useState();
  const [className, setClassName] = useState();
  return (
    <div>
      <GalleryBanner />
      <GalleryMid setSelectedImg={setSelectedImg} setClassName={setClassName} />
      {setSelectedImg && (
        <GalleryProject
          selectedImg={selectedImg}
          setSelectedImg={setSelectedImg}
          setClassName={setClassName}
        />
      )}
      <GalleryTheme />
      {selectedImg && (
        <GalleryModal
          selectedImg={selectedImg}
          setSelectedImg={setSelectedImg}
          className={className}
        />
      )}
      <Footer />
    </div>
  );
};

export default Gallery;
