import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import "./css/GalleryModal.css";
import { motion } from "framer-motion";

const GalleryModal = ({ selectedImg, setSelectedImg, className }) => {
  const handleClick = (e) => {
    setSelectedImg(null);
  };
  return (
    <motion.div
      className="gallery__modal"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <AiFillCloseCircle className="close__img__ic" onClick={handleClick} />
      <motion.img
        src={selectedImg}
        alt="Img selected"
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        className={className}
      />
    </motion.div>
  );
};

export default GalleryModal;
