import React from "react";
import GetInvolvedBanner from "./GetInvolvedBanner";
import GetInvolvedCommunity from "./GetInvolvedCommunity";
import GetInvolvedContact from "./GetInvolvedContact";
import GetInvolvedDonate from "./GetInvolvedDonate";
import Footer from "../../Components/Footer/Footer";

const GetInvolved = () => {
  return (
    <div>
      <GetInvolvedBanner />
      <GetInvolvedCommunity />
      <GetInvolvedDonate />
      <GetInvolvedContact />
      <Footer />
    </div>
  );
};

export default GetInvolved;
