import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/shadao__logo.png";
import "./Logo.css";

const Logo = () => {
  return (
    <div className="logo">
      <div>
        {" "}
        <Link to="/" className="link__logo">
          <img src={logo} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default Logo;
