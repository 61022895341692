import React from "react";
import { project_data } from "../../Data";
import "./css/ProjectWasteManagement.css";

const ProjectWasteManagement = () => {
  return (
    <div className="project__waste__management" id="waste__material__project">
      <div className="project__waste__management__container">
        <div className="project__waste__management__Img"></div>
        <div className="project__waste__management__desc">
          <div className="project__waste__management__header">
            <p>{project_data[2].header}</p>
          </div>
          <div className="project__waste__management__para">
            <p>{project_data[2].desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectWasteManagement;
