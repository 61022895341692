import React from 'react'
import { NavLink } from 'react-router-dom'
// import MobileNavigation from "../MobileNavigation/MobileNavigation";
// import MobileHook from "../../Hooks/MobileHook";

import './Navbar.css'

const Navbar = () => {
  // const { mobile } = MobileHook(isMobile);
  // console.log(mobile);
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth - 60);
  // // const [isMobile, setMobile] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("resize", () => {
  //     setScreenWidth(window.innerWidth - 60);
  //     console.log(screenWidth);
  //   });
  // }, [screenWidth]);
  let activeClassName = 'decorate'
  return (
    <div className="navbar">
      {/* {screenWidth <= 450 ? (
        <MobileNavigation />
      ) : ( */}
      <div className="links__container">
        <nav>
          <ul>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? activeClassName : 'link'
                }
                end
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive ? activeClassName : 'link'
                }
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/projects"
                className={({ isActive }) =>
                  isActive ? activeClassName : 'link'
                }
              >
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/publications"
                className={({ isActive }) =>
                  isActive ? activeClassName : 'link'
                }
              >
                Publications
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/gallery"
                className={({ isActive }) =>
                  isActive ? activeClassName : 'link'
                }
              >
                Gallery
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="nav__contact__us">
          <NavLink to="/get-involved" className="link">
            Get Involved
          </NavLink>
        </div>
      </div>
      {/* )} */}
    </div>
  )
}

export default Navbar
