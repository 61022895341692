import React from "react";
import Carousel, {
  slidesToShowPlugin,
  slidesToScrollPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { homeBannerImages } from "../../Data";
import "./css/CarouselHome.css";

const CarouselHome = () => {
  return (
    <div className="carousel">
      <Carousel
        plugins={[
          "centered",
          "infinite",
          "autoplay",
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 2,
              interval: 5000,
            },
          },
          {
            resolve: slidesToScrollPlugin,
            options: {
              numberOfSlides: 2,
            },
          },
        ]}
        animationSpeed={2000}
      >
        {homeBannerImages.map(({ id, path, desc }) => (
          <div className="carousel__Img__container">
            <div className="carousel__desc">
              <p>{desc}</p>
            </div>
            <img src={path} alt={id} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselHome;
