import React from "react";
import "./css/ProjectChildren.css";
import { project_data } from "../../Data";

const ProjectChildren = () => {
  return (
    <div className="project__children" id="project__children">
      <div className="project__children__container">
        <div className="project__children__Img"></div>
        <div className="project__children__desc">
          <div className="project__children__header">
            <p>{project_data[1].header}</p>
          </div>
          <div className="project__children__para">
            <p>{project_data[1].desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectChildren;
