import React from "react";
import "./css/GalleryTheme.css";

const GalleryTheme = () => {
  return (
    <div className="gallery__theme__">
      <h1>
        <span className="first">Make</span> a{" "}
        <span className="last">difference</span>
      </h1>
    </div>
  );
};

export default GalleryTheme;
