import React, { useState, useRef } from "react";
import { CgPlayButtonO, CgPlayPauseO } from "react-icons/cg";
import Logo from "../../Components/Logo/Logo";
import Navbar from "../../Components/Navbar/Navbar";
import lushotoView from "../../Assets/Video/gallery__banner__footage.mp4";
import "./css/GalleryBanner.css";
import MobileNavigation from "../../Components/MobileNavigation/MobileNavigation";
import MobileHook from "../../Hooks/MobileHook";

const GalleryBanner = ({ width, isMobile }) => {
  const devWidth = MobileHook(width);
  const [play, playVideo] = useState(true);
  //

  const videoRef = useRef(null);
  //
  const pauseVideo = () => {
    if (play) {
      videoRef.current.pause();
      playVideo(false);
    }
  };
  const playAgain = () => {
    videoRef.current.play();
    playVideo(true);
  };
  return (
    <div className="gallery__banner">
      {devWidth.width <= 450 ? (
        <div className="gallery__banner__nav__mobile">
          <MobileNavigation />
        </div>
      ) : (
        <div className="gallery__banner__nav__container">
          <Logo />
          <Navbar />
          {/* <WeatherWidget /> */}
        </div>
      )}
      <video autoPlay loop muted className="lushoto__footage" ref={videoRef}>
        <source src={lushotoView} type="video/mp4" />
      </video>
      <div className="gallery__theme__contaner">
        <div className="gallery__theme">
          <h1>LUSHOTO</h1>
        </div>
      </div>
      <div className="gallery__banner__lower">
        {play ? (
          <CgPlayPauseO
            className="gallery__video__controller"
            onClick={pauseVideo}
          />
        ) : (
          <CgPlayButtonO
            className="gallery__video__controller"
            onClick={playAgain}
          />
        )}
      </div>
    </div>
  );
};

export default GalleryBanner;
