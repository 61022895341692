export const homeBannerImages = [
  {
    id: 0,
    path: require('./Assets/animal_project.webp'),
    desc: 'Community Animal Project',
  },
  {
    id: 1,
    path: require('./Assets/children_support.webp'),
    desc: 'Children Support Program',
  },
  {
    id: 2,
    path: require('./Assets/heritage_promotion.webp'),
    desc: 'Community Heritage promotion',
  },
]
//
export const activities = [
  {
    activity: 1,
    title: 'Community Development Activities',
    text:
      'Promoting the involvement of the community in development activities',
    img_front: require('./Assets/Icons/community_front.png'),
    img_back: require('./Assets/Icons/community_back.png'),
  },
  {
    activitity: 2,
    title: 'HIV/AIDS',
    text: 'Enhancing the knowledge in HIV/AIDS and gender issues',
    img_front: require('./Assets/Icons/aids_front.png'),
    img_back: require('./Assets/Icons/aids_back.png'),
  },
  {
    activitity: 3,
    title: 'Environmental Conservation',
    text: 'Promoting environmental conservation',
    img_front: require('./Assets/Icons/conservation_front.png'),
    img_back: require('./Assets/Icons/conservation_back.png'),
  },
  {
    activitity: 4,
    title: 'Social Servives',
    text:
      'Supporting the community in social services improvement such as health, water, education',
    img_front: require('./Assets/Icons/social_worker_front.png'),
    img_back: require('./Assets/Icons/social_worker_back.png'),
  },
  {
    activitity: 5,
    title: 'Support Researchers',
    text:
      'Supporting researches related to Health, Education, Agriculture, Environment, Mineral, Tourism and Industries',
    img_front: require('./Assets/Icons/health_front.png'),
    img_back: require('./Assets/Icons/health_back.png'),
  },
  {
    activitity: 6,
    title: 'Vocation Education',
    text:
      'Supporting vocation education to community and empower community economically',
    img_front: require('./Assets/Icons/education_front.png'),
    img_back: require('./Assets/Icons/education_back.png'),
  },
]
export const community = {
  header: 'Community Development',
  desc: `We are fully commited in promoting sustainable utilization of 
    available resources through trainings, seminars, research and 
    collaborating with community, Government and other development stakeholders.
    The organization implement its objectives through social empowerment by
     mobilizing local community in participation of community development. 
     Though economic mitigation the organization empower the youth, women 
     and men in the community into realization of better livelihood, the 
     program put infuses in utilization of resources in sectors like 
     Agriculture and livestock keeping through capacity building to the
      community on sustainable utilization of resources with livelihood development. `,
}
// About page data
export const about_data = [
  {
    id: 'profile',
    header: 'SHADAO',
    desc: `Social Heritage and Development Appraisal 
    Organization (SHADAO) is the organization duly 
    established to build sense of self independence 
    within the community. In teams of mind sets and 
    social-economic well-being. SHADAO got fully 
    registration as Non-Government Organization as 
    per NGO’s Act, No.24 of 2002 with its amendment. 
    SHADAO have agreed to establish an organization 
    in order to contribute much on promoting development 
    in Tanzania community, through collaborating with 
    Community, Government and other development stakeholders.`,
  },
]
export const governance_data = [
  {
    id: 'members',
    header: 'Members',
    desc: `The Members of SHADAO are the highest authority 
    in all matters related to the running of the organization. 
    They exercise their authority mainly through the Annual 
    General Meeting or extraordinary meetings.  `,
    icon: require('./Assets/Icons/member.png'),
    divider: require('./Assets/Icons/divider.png'),
  },
  {
    id: 'board__of__directors',
    header: 'Board of Directors',
    desc: `The Board of Directors is the second principal organ 
    of the Organization. It is a Governing Board providing regular 
    oversight of the activities of the Organization. The Board’s 
    roles include: approving any changes to the structure of the
     Organization, approving policy and changes or additions to 
     rules, regulations and procedures of the Organization, 
     providing final approval of annual plans and budgets for 
     endorsement at the Annual General Meeting, review of annual 
     reports for approval by the AGM, review and discussion of the 
     financial report with the auditors and presenting audited 
     financial statements for approval by the AGM; and, the 
     recruitment and performance management of the Executive Director.`,
    icon: require('./Assets/Icons/directors.png'),
    divider: require('./Assets/Icons/divider.png'),
  },
  {
    id: 'management__team',
    header: 'Management Team',
    desc: `The implementation of the functions of the organization is 
    vested in its management team which is comprised of the management 
    and staff - led by the Executive Director. The Executive Director reports 
    to the Board of Directors in governance matters and is also a secretary 
    to the Members. Additionally, the Executive Director maintains a 
    relationship with the Development Partners in relation to funding issues. 
    The number of staff and structure of the organization in executing its 
    functions and realizing the organization’s objectives and activities, 
    is from time to time, determined by the Board of Directors.`,
    icon: require('./Assets/Icons/management_team.png'),
    divider: require('./Assets/Icons/divider.png'),
  },
]
// Project data
export const project_data = [
  {
    id: 'community__project',
    header: `Community Heritage Promotion `,
    desc: `Promotion of Usambara Mountains Cultural 
    Tourism programs, Hiking, Trekking and cycling 
    around the Usambara Mountains and the culture of 
    Tanzania through providing basic skills to local 
    youth, marketing of Cultural programs worldwide 
    to realize the organization objectives of better 
    utilization of cultural resource for livelihood 
    improvement as well as sustainable community development. 
    Different cultural activities and events can be organized 
    as an opportunity for Inter-cultural experiences and expression 
    also the organization promote preservation of community culture 
    and heritage for future generation.  `,
  },
  {
    id: 'children__support',
    header: 'Children Support Program',
    desc: `Social Heritage and Development Appraisal Organization 
    (SHADAO) through its social empowerment program has come 
    into realization that there is a great challenges in the 
    community on increasing number of vulnerable children and 
    equipped children with confidence and well prepared to be 
    better civilian as well as skilled in all sphere of life.  
    The program intend to reach most marginalized children 
    found around  Tanzania community through mobilizing the 
    community to take action on development challenges and 
    come together to solve community problems with the 
    involvement of other stakeholders and development partners 
    through giving support in Education, sports and child health.
    `,
  },
  {
    id: 'waste__management',
    header: 'Waste Material Management',
    desc: `SHADAO initiate the project in waste material 
    management and reuse to mobilize the community towards 
    the behavior change on the environment utilization of 
    plastic waste and decomposed waste in an environmentally 
    friendly way and economic productive way like plastic 
    waste as agricultural/garden tools or facility as well as 
    to the decomposed waste as agriculture supply.      `,
  },
  {
    id: 'community__animal__project',
    header: 'Animal Health Project',
    desc: ` Livestock subsector is facing number of challenges 
    including low productivity, animal diseases, feeds and access 
    to market for livestock and livestock products. The major 
    contributor of these challenges is lack of knowledge and 
    skills (technical knowhow) on livestock production. This is 
    due to few numbers of livestock extension officers in community, 
    extension approach used and limited resources in this subsector, 
    hence this project seek to address these challenges among small 
    scale livestock keepers in rural community.`,
  },
  {
    id: 'project__disability',
    header: 'Children Disabilities Rehabilitation',
    desc: `There are children with disabilities in Lushoto district 
    who lack appropriate and adequate rehabilitation services for 
    their development and functional performance. The communities 
    in which these children are living are not aware of the problem 
    and therefore continue to discriminate and/or stigmatize people 
    with disabilities. SHADAO is therefore inclusively working with 
    local communities to ensure quality health to children with disabilities 
    through rehabilitation. At hand the organization has a project of identifying, 
    assessing/evaluating, planning and intervening health issues related to disability 
    and developmental delay to children with disabilities. It is a rewarding entry point as 
    children with disabilities are now brought out of their lock-ups in the homes due to 
    stigmatization or discrimination as the project continues to create awareness to the 
    communities. Some of the parents/caregivers and children with disabilities effectively 
    participate in interventions. The parents recommend that the project is quite relevant
    with the problem they encounter as it gives them an insight on the issues pertaining
    to disability as well as it tries to solve the problem through rehabilitation
    measures using locally available resources. The organization regularly hires 
    qualified Occupational Therapists who guide intervention programs for these children. 
    The project is applying a Community Based Inclusive Development (CBID) Approach which 
    ensures that children with disabilities inclusively participate in a variety of 
    development programs in their communities. 
    `,
  },
]
// Get Involved -- Contact Us
export const get_involved_data = {
  id: 'get__involved__community',
  header: 'Community Volunteers Program',
  text: `Are you feel blessed and honor to share your experience, skills, 
    time and efforts to sustain the community development and contribute 
    collaborative solution? Join SHADAO Community Volunteers Program.  
    The organization arrange and mobilize volunteer program in the community 
    which involve different expertism with in the country and all over the world. 
    Working in development projects in different sectors like Animal health project, 
    Education in primary and secondary schools, Health in Dispensaries, 
    `,
}
//
// Images for publication page
export const event_gallery = [
  {
    id: 'boy__with__ball',
    path: require('./Assets/boy_with_ball.webp'),
  },
  {
    id: 'cleaning__ocean',
    path: require('./Assets/cleaning_ocean.webp'),
  },
  {
    id: 'education__phone',
    path: require('./Assets/education.webp'),
  },
  // {
  //   id: "girl__choir",
  //   path: require("./Assets/girl_choir.webp"),
  // },
  // {
  //   id: "kids__tz",
  //   path: require("./Assets/kida_tz.webp"),
  // },
  {
    id: 'kids__rand',
    path: require('./Assets/kids.webp'),
  },
]
// Gallery page
export const upperImages = [
  {
    id: 'gallery__left__main',
    path: require('./Assets/cultural_heritage.webp'),
  },
  {
    id: 'gallery__mid__top',
    path: require('./Assets/shadao__tourists__five.webp'),
  },
  {
    id: 'gallery__right__main',
    path: require('./Assets/shadao__tourists__eight.webp'),
  },
  {
    id: 'gallery__left__mid',
    path: require('./Assets/shadao__tourists__eleven.webp'),
  },

  {
    id: 'gallery__mid__',
    path: require('./Assets/shadao__tourists__nine.webp'),
  },
  {
    id: 'gallery__mid__main',
    path: require('./Assets/shadao__img__one.webp'),
  },
  {
    id: 'gallery__right__mid',
    path: require('./Assets/shadao__tourists.webp'),
  },

  {
    id: 'gallery__left__lower',
    path: require('./Assets/shadao__tourists__four.webp'),
  },

  {
    id: 'gallery__right__lower',
    path: require('./Assets/shadao__tourists__six.webp'),
  },
]
// Gallery lower
export const animalProjectGallery = [
  {
    id: 'animal__project__one',
    path: require('./Assets/animal__project__one.webp'),
  },
  {
    id: 'animal__project__four',
    path: require('./Assets/animal__project__four.webp'),
  },
  {
    id: 'animal__project__three',
    path: require('./Assets/animal__project__three.webp'),
  },
  {
    id: 'shadao__animal__project__one',
    path: require('./Assets/shadao__animal__project__one.webp'),
  },
  {
    id: 'shadao__sheep',
    path: require('./Assets/shadao__sheep.webp'),
  },
]
