import React from "react";
import "./css/GetInvolvedDonate.css";
import { TbBrandWhatsapp } from "react-icons/tb";
import { BsMailbox, BsEnvelope } from "react-icons/bs";
import { sendWhatsAppMSG } from "../../SocialMedia";

const GetInvolvedDonate = () => {
  return (
    <div className="get__involved__donate">
      <div className="get__involved__donate__container">
        <div className="get__involved__donate__info">
          <h1>Donate</h1>
          <p>
            You can donate in any of shadao programs to support community
            sustainable development.
          </p>
        </div>
        <div className="get__involved__donate__contact">
          <div className="get__involved__contact__one">
            <h3>
              <span className="get__involved__mailbox">
                <BsMailbox className="bs__mailbox" />
              </span>
              SHADAO
            </h3>
            <p>P.o.Box 337</p>
            <p>Lushoto,Tanzania</p>
          </div>
          <div className="get__involved__email">
            <BsEnvelope className="get__involved__email__ic" />
            <span className="get__involved__email__add">info@shadao.or.tz</span>
          </div>
          <div
            className="get__involved__contact__two"
            onClick={sendWhatsAppMSG}
          >
            <TbBrandWhatsapp className="get__involved__whatsapp" />
            <span className="get__involved__whatsapp__number">
              +255 &nbsp;717&nbsp;261164
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInvolvedDonate;
