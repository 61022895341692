import React from "react";
import "./css/Profile.css";
import { about_data } from "../../Data";

const Profile = () => {
  return (
    <div className="profile">
      <div className="profile__container">
        <div className="profile__img__container"></div>
        <div className="profile__desc__container">
          <div className="profile__desc__header">
            <h1>{about_data[0].header}</h1>
          </div>
          <div className="about__desc__text">
            <p>{about_data[0].desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
